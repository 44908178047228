import { styled } from "styled-components";
import ArrowRight from "../images/Arrow_right.svg";
import { motion } from "framer-motion";
import { botToTop, leftToRight, rightToLeft } from "../utils/motion";
export default function Content() {
	return (
		<ContentWrapper>
			<motion.div
				initial="initial"
				whileInView="onscreen"
				variants={leftToRight}
				className=" mt-[230px] xl:mt-[200px] 2xl:mt-[300px] flex flex-col items-center xl:items-start gap-4 xl:gap-6 overflow-hidden"
				id="xchange"
			>
				<Slogan>Anonymity is the spirt of the trade</Slogan>
				<Description>Xchange and earn crypto anonymously leaving no traces behind.</Description>
				<Button
					className="max-md:scale-75 max-md:float-left"
					href="https://app.ghostxchange.io"
				>
					Enter App
					<img src={ArrowRight} alt="arrow right" />
				</Button>
			</motion.div>
		</ContentWrapper>
	);
}
const ContentWrapper = styled.div`
	position: relative;
	overflow: hidden;
	max-width: 497px;
	@media (max-width: 1279px) {
		max-width: 100%;
		padding: 0px 16px;
	}
`;
const Slogan = styled.h3`
	font-weight: 700;
	font-size: 42px;
	color: white;
	z-index: 20;
	line-height: 64px;
	margin: 0;

	@media (max-width: 1279px) {
		font-size: 24px;
		line-height: normal;
		text-align: center;
	}
`;
const Description = styled.p`
	font-weight: 400;
	z-index: 20;
	line-height: 30px;
	font-size: 20px;
	color: #ff4e4e;
	max-width: 414px;

	@media (max-width: 1279px) {
		font-size: 14px;
		line-height: normal;
		text-align: center;
	}
`;

const Button = styled.a`
	width: fit-content;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 12px 36px;
	text-decoration: none;
	gap: 8px;
	background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
		rgba(3, 3, 6, 0.5);
	border: 1px solid #ffffff;
	box-shadow: 0px 4px 12px rgba(255, 255, 255, 0.5);
	backdrop-filter: blur(6px);
	border-radius: 24px;
	color: #fff;
	z-index: 20;
	font-weight: 600;
	font-size: 17px;
	cursor: pointer;
	&:hover {
		opacity: 0.65;
	}
`;
