import { Disclosure } from "@headlessui/react";
import subtractIcon from "../images/subtract.svg";
import addIcon from "../images/add.svg";
import { motion } from "framer-motion";
import { botToTop, childVariant, parentVariant, topToBot } from "../utils/motion";

const frequentlyAskedLists = [
	{
		question: "Why should I use Ghost Xchange?",
		answer:
			"Ghost Xchange allows you to remain completely anonymous when sending crypto. The Blockchain does not lie, everyone can trace everything right back to your wallet. You deserve to uphold your privacy and go about your activities as any normal person.",
	},
	{
		question: "How does Ghost Xchange work?",
		answer:
			"Ghost Xchange uses a mechanism called the Phantom Protocol to make your transactions anonymous. At no point does Ghost Xchange have access to userfunds in the process. Ghost Xchange is an aggregator of non-custodial exchanges. Once a user request is received, this is then routed them through some of the top non- custodial exchanges in the world.",
	},
	{
		question: "What fees does Ghost Xchange charges?",
		answer:
			"Users will never pay any fees to Ghost Xchange to use our service. All fees are included in the quote once we optimise the best price for you.",
	},
	{
		question: "Do I need a KYC to use Ghost Xchange?",
		answer:
			"Users do not require a KYC to use Ghost Xchange. There are many automated screeners monitoring each transaction to ensure the validity of them. In the event a transaction becomes flagged, there will be correspondence from our exchange partners for you to provide extra information to comply with their KYC/AML policies.",
	},
	{
		question: "Why is my order is taking longer than expected?",
		answer:
			"Generally, orders are processed within 20 minutes on Ghost Xchange. However, in a rare case this exceed beyond 1 hour please contact Ghost support quoting your Order ID.",
	},
	{
		question: " How do I stake my $GHOST?",
		answer:
			"Head over to our staking tab, where you will be able to set the number of tokens you would like to stake and the duration. You will be able to see how what % of commission you will earn for each tier.",
	},
];

const FrequentlyAsked = () => {
	return (
		<>
			<motion.h3
				variants={topToBot}
				initial="initial"
				whileInView="onscreen"
				className="text-[18px] xl:text-[36px] font-bold xl:leading-[64px] text-white"
				id="faq"
			>
				Frequently asked questions
			</motion.h3>
			<motion.div
				variants={parentVariant}
				initial="initial"
				whileInView="onscreen"
				className="w-full xl:w-[752px] mt-3 xl:mt-12"
			>
				{frequentlyAskedLists.map((item, index) => (
					<motion.div
						variants={childVariant}
						initial="initial"
						whileInView="onscreen"
						key={index}
					>
						<Disclosure defaultOpen={index === 0}>
							{({ open }: { open: boolean }) => (
								<>
									<div aria-expanded={open} className="p-5 group ">
										<Disclosure.Button className="flex w-full text-left justify-between rounded-lg bg-transparent">
											<span className="text-[14px] xl:text-[18px] font-semibold leading-normal text-white/50 group-aria-expanded:text-white">
												{index + 1}. {item.question}
											</span>
											<img src={open ? subtractIcon : addIcon} alt="" />
										</Disclosure.Button>
										<Disclosure.Panel className="mt-[10px] text-[12px] xl:text-[16px] font-[300] leading-normal text-white/70">
											{item.answer}
										</Disclosure.Panel>
									</div>
									<div className="mx-5 h-[1px] bg-[#1C1C1E]" />
								</>
							)}
						</Disclosure>
					</motion.div>
				))}
			</motion.div>
		</>
	);
};

export default FrequentlyAsked;
