import ghostLogo from "../images/ghost-logo.png";
import discordCircleIcon from "../images/discord-circle.svg";
import mediumCircleIcon from "../images/medium-circle.svg";
import twitterCircleIcon from "../images/twitter-circle.svg";
import telegramCircleIcon from "../images/telegram-circle.svg";
import { motion } from "framer-motion";
import { botToTop, topToBot } from "../utils/motion";

const Footer = () => {
	return (
		<div className="relative z-10 flex flex-col items-center">
			<motion.img
				variants={topToBot}
				initial="initial"
				whileInView="onscreen"
				src={ghostLogo}
				alt="ghost logo"
				className="w-[172px] h-[75px] object-cover mb-6"
			/>
			<motion.div
				variants={botToTop}
				initial="initial"
				whileInView="onscreen"
				className="flex gap-4 mb-12"
			>
				<a
					href="https://twitter.com/GexETH"
					className="transition-all ease-in-out hover:scale-105"
				>
					<img src={twitterCircleIcon} alt="" />
				</a>
				<a href="https://t.me/GEXtele" className="transition-all ease-in-out hover:scale-105">
					<img src={telegramCircleIcon} alt="" />
				</a>
			</motion.div>
			<div className="bg-[#ABABAB]/10 backdrop-blur-[20px] w-full py-5 text-center">
				<p className="text-[14px] xl:text-[16px] leading-normal text-white/50">
					© 2023 Ghost Swap, All rights reserved.
				</p>
			</div>
		</div>
	);
};

export default Footer;
