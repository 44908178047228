import { styled } from "styled-components";
import { NavItem, NavWrapper } from "./Header";
import { Dispatch, SetStateAction } from "react";

export default function Sider({
	toggle,
	setToggle,
}: {
	toggle: boolean;
	setToggle: Dispatch<SetStateAction<boolean>>;
}) {
	return (
		<SiderWrapper
			style={{
				left: toggle ? "0" : "",
				right: toggle ? "0" : "",
				backgroundColor: toggle ? "rgb(3,3,6, 0.8)" : "",
			}}
		>
			<MainSider>
				<ul className="px-4 flex flex-col gap-4">
					<li className="text-white text-[16px]" onClick={() => setToggle(false)}>
						<a href="https://app.ghostxchange.io">Xchange</a>
					</li>
					<li className="text-white text-[16px]" onClick={() => setToggle(false)}>
						<a href="#about">About</a>
					</li>
					<li className="text-white text-[16px]" onClick={() => setToggle(false)}>
						<a href="#ghost-rewards">Ghost Rewards</a>
					</li>
					<li className="text-white text-[16px]" onClick={() => setToggle(false)}>
						<a href="#faq">FAQ</a>
					</li>
				</ul>
			</MainSider>
		</SiderWrapper>
	);
}
const SiderWrapper = styled.div`
	position: fixed;
	top: 99px;
	z-index: 1000;
	right: 100%;
	bottom: 0;
	height: 100%;
	transition: 0.5s;
	left: -100%;
`;
const MainSider = styled.div`
	padding: 25px 0;
	border-right: 1px solid rgba(255, 255, 255, 0.1);
	width: 250px;
	height: 100%;
	z-index: 1000;
	background-color: rgb(3, 3, 6);
`;

const Seperate = styled.div`
	width: 6px;
	height: 40px;
	position: absolute;
	left: 0px;
	top: 50%;
	transform: translateY(-50%);
	z-index: 1000;
	background: linear-gradient(180deg, #49a7f5 0%, #317ce0 100%);
	box-shadow: 0px 0px 5px rgba(139, 117, 245, 0.4);
	border-radius: 0px 8px 8px 0px;
`;
const SiderItemContent = styled.div`
	padding: 0 31.4px;
	display: flex;
	gap: 17px;
`;
const SiderItem = ({
	img,
	content,
	active,
}: {
	img: string;
	content: string;
	active: boolean;
}) => {
	const StyledSiderItem = styled.a`
		position: relative;
		display: flex;
		cursor: pointer;
		color: #fff;
	`;
	return (
		<StyledSiderItem className={`${active ? "linear" : ""}`}>
			{active && <Seperate />}
			<SiderItemContent>
				<img src={img} alt="icon" />
				<p>{content}</p>
			</SiderItemContent>
		</StyledSiderItem>
	);
};
