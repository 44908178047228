import { styled } from "styled-components";
import Header from "./components/Header";
import Content from "./components/Content";
import "particles.js/particles";
import { useEffect, useMemo, useRef, useState } from "react";
import Sider from "./components/Sider";
import ghostVideo from "./assets/videos/ghost-video.mp4";
import ghostGoldVideo from "./assets/videos/ghost-gold-video.mp4";
import bgGhostVideo from "./assets/videos/bg-ghost.mp4";
import FrequentlyAsked from "./components/FrequentlyAsked";
import Footer from "./components/Footer";
import ghostsBg from "./images/ghosts-bg.png";
import swapImg from "./images/swap.png";
import giftIcon from "./images/gift.svg";
import receiptIcon from "./images/receipt-discount.svg";
import peopleIcon from "./images/people.svg";
import linkCircleIcon from "./images/link-circle.svg";
import ghostRewadsImg from "./images/ghost-rewards.png";
import getStartedBg from "./images/get-started.png";
import arrowRightIcon from "./images/Arrow_right.svg";
import circleCarretLeftIcon from "./images/Circle_Carret_Left.svg";
import circleCarretRightIcon from "./images/Circle_Carret_Right.svg";
import { motion } from "framer-motion";
import vid1 from "./assets/videos/vid-1.mp4";
import vid2 from "./assets/videos/vid-2.mp4";
import vid3 from "./assets/videos/vid-3.mp4";
import vid4 from "./assets/videos/vid-4.mp4";
import vid5 from "./assets/videos/vid-5.mp4";
import vid6 from "./assets/videos/vid-6.mp4";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";

// import Swiper core and required modules
import { Autoplay, Navigation } from "swiper/modules";
import {
	botToTop,
	childVariant,
	leftToRight,
	parentVariant,
	rightToLeft,
	topToBot,
} from "./utils/motion";

// const particlesJS = (window as any).particlesJS;

const howItWorkList = [
	{
		id: 1,
		label: "Create Order",
		value: "Choose your crypto you would like to Xchange.",
	},
	{
		id: 2,
		label: "Amount",
		value:
			"Input Amount you would like to Xchange: Ghost will calculate the most competitive rates.",
	},
	{
		id: 3,
		label: "Address",
		value:
			"Input the receiving address: Input your receiving wallet address, checking it corresponds to the chain you have selected.",
	},
	{
		id: 4,
		label: "Choose Rate",
		value:
			"Choose your Rate: Fixed will remain at 1% flat fee. Variable rate is 0.5% but until the required amount of required block confirmations is completed, you may receive more or less crypto depending on the price at completion.",
	},
	{
		id: 5,
		label: "Send your funds",
		value:
			"Send your funds to generated wallet address: Open your chosen wallet and send your funds to the generated wallet address.",
	},
	{
		id: 6,
		label: "Vanished",
		value:
			"You will receive your funds to your chosen wallet within 20 minutes. Just like that your funds teleported through another dimension.",
	},
];

const videoSilder = [
	{
		sliderId: 1,
		url: vid1,
	},
	{
		sliderId: 2,
		url: vid2,
	},
	{
		sliderId: 3,
		url: vid3,
	},
	{
		sliderId: 4,
		url: vid4,
	},
	{
		sliderId: 5,
		url: vid5,
	},
	{
		sliderId: 6,
		url: vid6,
	},
];

function App() {
	const [toggle, setToggle] = useState(false);
	const sliderRef = useRef<any>(null);
	const videoSliderRef = useRef<any>(null);
	const [activeSlideIndex, setActiveSlideIndex] = useState(0);

	// useEffect(() => {
	// 	particlesJS.load("particles-js", "particles.json");
	// }, []);

	const renderArrowsSlider = useMemo(() => {
		if (!sliderRef.current) return;

		return (
			<div className="absolute bottom-0 z-10 flex items-center gap-1">
				<button onClick={() => sliderRef.current.swiper.slidePrev()}>
					<img src={circleCarretLeftIcon} alt="" />
				</button>
				<span className="text-[14px] xl:text-[20px] font-[300] text-white">
					{activeSlideIndex}/6
				</span>
				<button onClick={() => sliderRef.current.swiper.slideNext()}>
					<img src={circleCarretRightIcon} alt="" />
				</button>
			</div>
		);
	}, [sliderRef.current, activeSlideIndex]);

	const handleSlideChange = (swiper: any) => {
		const activeIndex = swiper.activeIndex;
		console.log({ activeIndex });
		setActiveSlideIndex(swiper.realIndex + 1);
	};

	useEffect(() => {
		if (videoSliderRef.current && videoSliderRef.current.swiper) {
			videoSliderRef.current.swiper.slideTo(activeSlideIndex - 1);
		}
		// Preload video of the next slide
		// const nextSlide = activeSlideIndex + 1;
		// console.log({ nextSlide });
		// if (nextSlide < videoSilder.length) {
		// 	const video = new Image();
		// 	video.src = videoSilder[nextSlide].url;
		// }
	}, [activeSlideIndex]);

	return (
		<>
			<Sider toggle={toggle} setToggle={setToggle} />
			<div className="bg-[#030306]">
				<Wrapper>
					<Header toggle={toggle} setToggle={setToggle} />
				</Wrapper>
			</div>
			<div
				className="relative w-ful h-[450px] xl:h-[650px] 2xl:h-[896px] bg-no-repeat bg-contain xl:bg-cover bg-top top-0 xl:-top-20"
				// style={{
				// 	backgroundImage: `url(${ghostsBg})`,
				// }}
			>
				<video
					autoPlay
					loop
					muted
					className="object-cover w-full h-full absolute top-0 right-0 left-0 -z-10 "
				>
					<source type="video/mp4" src={bgGhostVideo} />
				</video>
				<Wrapper>
					<div>
						<Content />
						<motion.video
							initial="initial"
							whileInView="onscreen"
							variants={botToTop}
							autoPlay
							loop
							muted
							className="object-cover w-[285px] max-md:left-0 max-md:right-0 h-[400px] xl:w-[712px] xl:h-[682px] absolute -top-20 2xl:top-10  xl:right-[100px] 2xl:right-[250px] -z-10 max-xl:mx-auto"
						>
							<source type="video/mp4" src={ghostVideo} />
						</motion.video>
					</div>
				</Wrapper>
			</div>
			<Wrapper>
				<div className="px-4 xl:px-0 max-md:mt-10">
					<div className="text-center scroll-smooth">
						<motion.h3
							initial="initial"
							whileInView="onscreen"
							variants={topToBot}
							className="text-white text-[24px] xl:text-[40px] font-bold"
							id="about"
						>
							About
						</motion.h3>
						<motion.p
							initial="initial"
							whileInView="onscreen"
							variants={botToTop}
							className="text-[18px] xl:text-[32px] font-[300] text-[#666666] mt-4 xl:mt-[74px]"
						>
							Ghost Xchange allows you to trade your crypto in absolute secrecy. Our swap ensures
							your identity remains hidden, providing you with the upmost privacy.
						</motion.p>
					</div>
					<div className="mt-10 xl:mt-[90px] flex flex-col-reverse xl:flex-row gap-10 xl:gap-[127px]">
						<motion.div initial="initial" whileInView="onscreen" variants={leftToRight}>
							<Swiper
								modules={[Navigation]}
								freeMode={true}
								loop={true}
								ref={sliderRef}
								// initialSlide={activeSlideIndex}
								onSwiper={(swiper) => {
									setActiveSlideIndex(swiper.realIndex + 1);
								}}
								onSlideChange={(swiper) => {
									// setActiveSlideIndex(swiper.realIndex + 1);

									handleSlideChange(swiper);
								}}
								spaceBetween={16}
								className="w-full xl:w-[498px] relative h-fit"
							>
								{howItWorkList.map((item, index) => (
									<SwiperSlide className="pt-[30px] max-w-[498px]">
										<div className="bg-white/10 backdrop-blur-[10px] rounded-3xl p-4 py-8 xl:p-11 h-[294px] relative">
											<h5 className="text-[14px] xl:text-[20px] font-semibold text-white">
												{item.label}
											</h5>
											<p className="mt-4 text-white/50 text-[12px] xl:text-[16px] font-normal leading-6">
												{item.value}
											</p>
											<div className="absolute z-20 -top-6 xl:-top-[33px] left-11 w-12 h-12 xl:w-[66px] xl:h-[66px] rounded-full bg-[#1C1C1F] border-[4px] border-[#030306] flex items-center justify-center overflow-visible">
												<span className="text-[16px] xl:text-[30px] font-semibold text-white">
													{index + 1}
												</span>
											</div>
										</div>
									</SwiperSlide>
								))}
								{renderArrowsSlider}
							</Swiper>
						</motion.div>

						<motion.div
							initial="initial"
							whileInView="onscreen"
							variants={rightToLeft}
							className="text-center flex-1"
						>
							<Swiper
								modules={[Navigation]}
								freeMode={true}
								loop={true}
								ref={videoSliderRef}
								className="w-full xl:w-[498px] relative h-fit"
							>
								{videoSilder.map(
									(video) => (
										// video.sliderId === activeSlideIndex ? (
										<SwiperSlide key={video.sliderId}>
											<video
												preload="metadata"
												autoPlay
												loop
												muted
												className="object-cover w-full h-full"
											>
												<source type="video/mp4" src={video.url} />
											</video>
										</SwiperSlide>
									),
									// ) : null,
								)}
							</Swiper>
							{/* {videoSilder.map((video) =>
								video.sliderId === activeSlideIndex ? (
									<video autoPlay loop muted className="object-cover w-full h-full">
										<source type="video/mp4" src={video.url} />
									</video>
								) : null,
							)} */}

							{/* <img
								src={swapImg}
								alt=""
								className="w-full h-full object-cover xl:w-[515px] xl:h-[483px]"
							/>
							<span className="text-white/50 text-[14px] xl:text-[20px] mt-2">
								Phantom Protocol
							</span> */}
						</motion.div>
					</div>
					<div className="mt-10 xl:mt-[134px]">
						<motion.div
							variants={botToTop}
							initial="initial"
							whileInView="onscreen"
							className="flex items-center justify-center gap-4"
							id="ghost-rewards"
						>
							<img src={giftIcon} alt="" />
							<h3 className="text-white text-[24px] xl:text-[40px] font-bold">Ghost Rewards</h3>
						</motion.div>
						<div className="mt-4 xl:mt-[62px] flex flex-col-reverse gap-x-14 xl:flex-row justify-between items-center">
							<motion.div
								variants={parentVariant}
								initial="initial"
								whileInView="onscreen"
								className="flex flex-col mt-4 xl:mt-0 gap-4 xl:gap-6 max-w-[511px]"
							>
								<motion.div
									variants={childVariant}
									initial="initial"
									whileInView="onscreen"
									className="flex items-start gap-4"
								>
									<img src={receiptIcon} alt="" />
									<p className="text-white/60 text-[12px] xl:text-[16px] leading-6">
										Ghost stake rewards our most loyal ghouls, for locking up their $GHOST in return
										for a percentage of the swap fees from the Xchange.
									</p>
								</motion.div>
								<motion.div
									variants={childVariant}
									initial="initial"
									whileInView="onscreen"
									className="flex items-start  gap-4"
								>
									<img src={peopleIcon} alt="" />
									<p className="text-white/60 text-[12px] xl:text-[16px] leading-6">
										Users will also be able to benefit from additional $GHOST through the Cryptic
										Ghost club.
									</p>
								</motion.div>
								<motion.div
									variants={childVariant}
									initial="initial"
									whileInView="onscreen"
									className="flex items-start  gap-4"
								>
									<img src={linkCircleIcon} alt="" />
									<p className="text-white/60 text-[12px] xl:text-[16px] leading-6">
										Generate and share your referral link among your fellow ghouls and earn more
										$GHOST.
									</p>
								</motion.div>
							</motion.div>
							<motion.div
								variants={rightToLeft}
								initial="initial"
								whileInView="onscreen"
								className="w-full xl:h-[391px]"
							>
								<video
									autoPlay
									playsInline
									loop
									preload="auto"
									className="object-cover w-full h-full"
								>
									<source type="video/mp4" src={ghostGoldVideo} />
								</video>
							</motion.div>
						</div>
					</div>
					<motion.div
						variants={botToTop}
						initial="initial"
						whileInView="onscreen"
						className="mt-10 xl:mt-[187px] h-[150px] xl:h-[196px] bg-no-repeat bg-cover rounded-3xl p-4 xl:py-[50px] xl:px-[56px] flex flex-col xl:flex-row justify-between items-center"
						style={{
							backgroundImage: `url(${getStartedBg})`,
						}}
					>
						<h4 className="text-[18px] xl:text-[32px] font-semibold text-center text-[#030306] max-w-[283px]">
							Are you ready to get started?
						</h4>
						<button
							type="button"
							className="px-9 py-4 bg-[#030306] rounded-full backdrop-blur-[30px] flex items-center gap-3 hover:scale-105 ease-in-out transition-all"
						>
							<span className="text-[12px] xl:text-[16px] text-white font-semibold">
								Enter App now
							</span>
							<img src={arrowRightIcon} alt="" />
						</button>
					</motion.div>
				</div>
			</Wrapper>
			<div className="pt-10	xl:pt-[185px] flex flex-col items-center relative z-10 mb-[100px] xl:mb-[200px]">
				<FrequentlyAsked />
			</div>
			<Footer />
		</>
	);
}
// const ParticlesStyled = styled.div`
// 	position: fixed;
// 	top: 0;
// 	left: 0;
// 	right: 0;
// 	bottom: 0;
// `;
const Wrapper = styled.div`
	padding: 0;
	max-width: 1140px;
	margin: 0 auto;
`;

export default App;
