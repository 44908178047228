export const botToTop = {
	initial: {
		y: 100,
		opacity: 0,
	},
	onscreen: {
		y: 0,
		opacity: 1,
		transition: {
			duration: 0.6,
		},
	},
};

export const leftToRight = {
	initial: {
		x: -100,
		opacity: 0,
	},
	onscreen: {
		x: 0,
		opacity: 1,
		transition: {
			duration: 0.5,
		},
	},
};

export const rightToLeft = {
	initial: {
		x: 100,
		opacity: 0,
	},
	onscreen: {
		x: 0,
		opacity: 1,
		transition: {
			duration: 0.5,
		},
	},
};

export const topToBot = {
	initial: {
		y: -100,
		opacity: 0,
	},
	onscreen: {
		y: 0,
		opacity: 1,
		transition: {
			duration: 0.5,
		},
	},
};

export const parentVariant = {
	initial: {
		transition: { staggerChildren: 0.07, delayChildren: 0.1 },
	},
	onscreen: {
		transition: { staggerChildren: 0.05, staggerDirection: -1 },
	},
};

export const childVariant = {
	initial: {
		y: 50,
		opacity: 0,
		transition: {
			y: { stiffness: 1000, velocity: -100 },
		},
	},
	onscreen: {
		y: 0,
		opacity: 1,
		transition: {
			y: { stiffness: 1000 },
		},
	},
};
