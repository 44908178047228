import { styled } from "styled-components";
const BarWrapper = styled.button`
	border: none;
	z-index: 100;
	background-color: transparent;
	display: inline-flex;
	color: #fff;
	@media (min-width: 1180px) {
		display: none;
	}
`;
export default function Bar({
	toggle,
	setToggle,
}: {
	toggle: boolean;
	setToggle: Function;
}) {
	return (
		<BarWrapper onClick={() => setToggle(!toggle)}>
			{toggle ? (
				<svg
					xmlns="http://www.w3.org/2000/svg"
					fill="none"
					viewBox="0 0 24 24"
					width={48}
					height={48}
					strokeWidth={1.5}
					stroke="currentColor"
					className="w-6 h-6"
				>
					<path
						strokeLinecap="round"
						strokeLinejoin="round"
						d="M3.75 6.75h16.5M3.75 12H12m-8.25 5.25h16.5"
					/>
				</svg>
			) : (
				<svg
					xmlns="http://www.w3.org/2000/svg"
					fill="none"
					viewBox="0 0 24 24"
					width={48}
					height={48}
					strokeWidth={1.5}
					stroke="currentColor"
					className="w-6 h-6"
				>
					<path
						strokeLinecap="round"
						strokeLinejoin="round"
						d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
					/>
				</svg>
			)}
		</BarWrapper>
	);
}
