import { styled } from "styled-components";
import Logo from "../images/logo.svg";
import Bar from "./Bar";
export default function Header({
	toggle,
	setToggle,
}: {
	toggle: boolean;
	setToggle: Function;
}) {
	return (
		<HeaderContainer>
			<div className="relative z-10 logo">
				<Bar toggle={toggle} setToggle={setToggle} />
				<img src={Logo} alt="logo" />
			</div>
			<NavWrapper>
				<NavItem href="https://app.ghostxchange.io">
					<span>XChange</span>
				</NavItem>
				<NavItem href="https://app.ghostxchange.io/stake">
					<span>Stake</span>
				</NavItem>
				<NavItem href="#about">
					<span>About</span>
				</NavItem>
				<NavItem href="#ghost-rewards">
					<span>Ghost Rewards</span>
				</NavItem>
				<NavItem href="#faq">
					<span>FAQ</span>
				</NavItem>
			</NavWrapper>
			<Button href="https://app.ghostxchange.io">Enter App</Button>
		</HeaderContainer>
	);
}
const HeaderContainer = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 20px 0;
	border-bottom: 1px solid rgba(255, 255, 255, 0.1);
	background-color: #030306;
	/* margin-bottom: 82px; */
	@media (max-width: 1536px) {
		/* margin-bottom: 22px; */
	}
	@media (max-width: 1279px) {
		margin: 0px 16px;
	}
`;
export const NavWrapper = styled.div`
	display: none;
	font-weight: 500;
	font-size: 17px;
	position: relative;
	z-index: 10;
	@media (min-width: 1180px) {
		display: flex;
		align-items: center;
		gap: 41px;
	}
`;
export const NavItem = styled.a`
	display: flex;
	justify-content: center;
	align-items: center;
	text-decoration: none;
	color: white;
`;
const Button = styled.a`
	text-decoration: none;
	padding: 10px 24px;
	background-color: white;
	color: #333;
	border-radius: 24px;
	border: none;
	font-weight: 600;
	font-size: 17px;
	z-index: 100;
	cursor: pointer;
	&:hover {
		opacity: 0.65;
	}
	@media (max-width: 374px) {
		padding: 10px 14px;
	}
`;
